import * as React from 'react';
import PageTemplate from '../components/pageTemplate'
import {LocationContext} from "../routes";
import {useIntl} from "gatsby-plugin-intl";

const Imprint = ({location}: {
  location: any
}) => {
  const intl = useIntl();
  return <LocationContext.Provider value={location}>
    <PageTemplate title="">
      {intl.locale === "de" ? <DeDPR/> : <EnDPR/>}
    </PageTemplate>
  </LocationContext.Provider>
};

const EnDPR: React.FC = () => {
  return (<>
    <h3 style={{color: "#558335"}}>University of Graz Data Protection Declaration</h3>
    <p>
      The University of Graz places great value on the protection of the personal data of its users, suppliers and customers. We treat your personal data confidentially and process it according to the legal data-protection regulations. For this reason, we are informing you about the purpose and scope of data-processing carried out by us.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Which data is processed and for how long is it stored?</div>
    <p>
      We do not process any personal data if you do not use the Login function of this website [Note: there is currently no Login function activated for this website]. We process the following personal data in case of your use of the Login function for access to the information, data, tools, and services that require a basic registration: e-mail address, first name, last name, choice between non-commercial or other interest, and a short reason-of-interest notice of 10-30 words, which you will be asked to enter in the registration form when registering for Login.
      The data is processed for registration and access to the Login-enabled information, data, tools, and services and for usage statistics. Furthermore, we use your e-mail address to contact you for information related to the website use if needed. We process your personal data only as much as is necessary, for the time until requested deletion or restriction, and beyond that for the duration of legal retention periods and documentation obligations.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Who receives your data?</div>
    <p>
      Your data will be processed within the Wegener Center of the University of Graz only; there will be no transfer to external recipients.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>What data protection rights do you have?</div>
    <p>
      You have the following basic rights. If you wish to exercise these rights, you can do so informally without giving reasons by e-mail to wegcenter@uni-graz.at
      <br/>right of information,
      <br/>right of correction,
      <br/>right of deletion,
      <br/>right of restriction,
      <br/>right of data portability, and
      <br/>right of appeal.
      <br/>If you think that the processing of your data impinges on the data protection law or that your entitlements under the data protection law are in some way breached, you can make a complaint to the Austrian Data-Protection Authority at Wickenburggasse 8, 1080 Vienna, Phone: +43 152 152-0, Mail: dsb@dsb.gv.at
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Who is responsible for data processing and who can you contact about it?</div>
    <p>
      Responsibility for data processing lies with:
      <br/>University of Graz – Wegener Center for Climate and Global Change
      <br/>Brandhofgasse 5, 8010 Graz, Mail: wegcenter@uni-graz.at
      <br/>You can contact the data-protection officer of the University of Graz via dsba@uni-graz.at
    </p>
  </>);
}

const DeDPR: React.FC = () => {
  return (<>
    <h3 style={{color: "#558335"}}>Universität Graz Datenschutzerklärung</h3>
    <p>
      Die Universität Graz legt großen Wert auf den Schutz der personenbezogenen Daten ihrer Nutzer, Lieferanten und Kunden. Wir behandeln Ihre personenbezogenen Daten vertraulich und verarbeiten sie gemäß den gesetzlichen Datenschutzbestimmungen. Aus diesem Grund informieren wir Sie über Zweck und Umfang der von uns durchgeführten Datenverarbeitung.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Welche Daten werden verarbeitet und wie lange werden sie gespeichert?</div>
    <p>
      Wir verarbeiten keine personenbezogenen Daten, wenn Sie die Login-Funktion dieser Website nicht nutzen [Anmerkung: für diese Website ist derzeit keine Login-Funktion aktiviert]. Wir verarbeiten die folgenden personenbezogenen Daten, wenn Sie die Login-Funktion für den Zugriff auf Informationen, Daten, Tools und Dienste verwenden, für die eine grundlegende Registrierung erforderlich ist: E-Mail-Adresse, Vorname, Nachname, Auswahl zwischen nichtkommerziell oder Sonstiges Interesse und eine kurze Mitteilung über den Grund des Interesses von 10 bis 30 Wörtern, die Sie bei der Registrierung für die Anmeldung in das Registrierungsformular eingeben müssen.
      Die Daten werden für die Registrierung und den Zugriff auf Informationen (Daten, Tools und Dienste), die ein Login benötigen, sowie für Nutzungsstatistiken verarbeitet. Darüber hinaus verwenden wir Ihre E-Mail-Adresse, um Sie bei Bedarf für Informationen mit Bezug zur Nutzung dieser Website zu kontaktieren. Wir verarbeiten Ihre personenbezogenen Daten nur so weit wie nötig für die Zeit bis zur gewünschten Löschung oder Einschränkung und darüber hinaus für die Dauer der gesetzlichen Aufbewahrungsfristen und Dokumentationspflichten.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Wer erhält Ihre Daten?</div>
    <p>
      Ihre Daten werden nur am Wegener Center der Universität Graz verarbeitet. Es erfolgt keine Übertragung an externe Empfänger.
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Welche Datenschutzrechte haben Sie?</div>
    <p>
      Sie haben folgende Grundrechte. Wenn Sie diese Rechte ausüben möchten, können Sie dies informell ohne Angabe von Gründen per E-Mail an wegcenter@uni-graz.at tun:
      <br/>Recht auf Auskunft über die betreffenden personenbezogenen Daten,
      <br/>Recht auf Berichtigung,
      <br/>Recht auf Löschung,
      <br/>Recht auf Beschränkung,
      <br/>Recht auf Datenübertragbarkeit und
      <br/>Recht auf Beschwerde.
      <br/>Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten das Datenschutzgesetz beeinträchtigt oder Ihre datenschutzrechtlichen Ansprüche in irgendeiner Weise verletzt werden, können Sie eine Beschwerde bei der Österreichischen Datenschutzbehörde in der Wickenburggasse 8, 1080 Wien, Telefon, einreichen. Telefon: +43 152 152-0, Mail: dsb@dsb.gv.at
    </p>
    <div style={{color: "#558335", fontSize: "large"}}>Wer ist für die Datenverarbeitung verantwortlich und an wen können Sie sich wenden?</div>
    <p>
      Die Verantwortung für die Datenverarbeitung liegt bei:
      <br/>Universität Graz – Wegener Center für Klima und Globalen Wandel
      <br/>Brandhofgasse 5, 8010 Graz, Mail: wegcenter@uni-graz.at
      <br/>Sie können die/den Datenschutzbeauftragte/n der Universität Graz unter dsba@uni-graz.at kontaktieren.
    </p>
  </>);
}

export default Imprint;
